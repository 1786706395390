import React from 'react'

const SlideOutLandingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="580.252" height="300.688" viewBox="0 0 622.252 390.688">
        <g id="LS_White" transform="translate(-59.294 -35.532)">
          <path id="Path_10237" data-name="Path 10237" d="M55.262,141.182H32.277v3.8H48.784v69.615h24.94v16.176H80.2V210.8H55.262Z" transform="translate(5.376 190.376)" fill="#457e5c"/>
          <path id="Path_10238" data-name="Path 10238" d="M123.472,107.763c-2.844,0-3.959,0-3.959,5.2v13.323h6.481v-14.4c0-3.085-1-4.116-2.522-4.116" transform="translate(162.571 130.157)" fill="#457e5c"/>
          <path id="Path_10239" data-name="Path 10239" d="M126.721,101.867c26.1,0,31.575,15.784,31.575,29.029v1.415h6.481v-7.778c0-17.557-9.759-26.462-29.011-26.462-7.787,0-13.934,1.575-18.527,4.707a47.3,47.3,0,0,1,9.482-.911" transform="translate(158.475 112.691)" fill="#457e5c"/>
          <path id="Path_10240" data-name="Path 10240" d="M119.513,129.071c0,3.382,1.087,3.926,3.69,3.926,2.258,0,3.306-.017,3.343-6.946,0-.933-.022-2-.025-3.34h-7.008v6.36Z" transform="translate(162.571 157.093)" fill="#457e5c"/>
          <path id="Path_10241" data-name="Path 10241" d="M123.559,113.018v3.8h23.817v46.109h6.478V113.018Z" transform="translate(169.863 139.626)" fill="#457e5c"/>
          <path id="Path_10242" data-name="Path 10242" d="M64.188,138.278c-1.379,0-3.458,0-3.458,7.871v35.733c0,8.569,2.6,8.569,3.575,8.569,1.129,0,3.231,0,3.231-8.569v-1.905a28.151,28.151,0,0,1-.325-4.461v-34.15c-.56-3.074-1.765-3.088-3.023-3.088" transform="translate(56.648 185.144)" fill="#457e5c"/>
          <path id="Path_10243" data-name="Path 10243" d="M77.038,128.585c-7.47,0-13.632,1.648-18.353,4.716a46.064,46.064,0,0,1,9.308-.919c19.919,0,31.346,10.877,31.346,29.844v35.383c0,8.467-1.3,15.212-3.97,20.387,7.184-4.7,10.451-13.36,10.451-26.75V155.856c0-17.585-10.222-27.272-28.782-27.272" transform="translate(52.962 167.676)" fill="#457e5c"/>
          <path id="Path_10244" data-name="Path 10244" d="M97.05,117.867h15.36v85.785h6.481V114.07H96.9Z" transform="translate(121.822 141.522)" fill="#457e5c"/>
          <path id="Path_10245" data-name="Path 10245" d="M100.4,117.867l8.4,25.876-1.216-29.673H84.372v3.8Z" transform="translate(99.248 141.522)" fill="#457e5c"/>
          <path id="Path_10246" data-name="Path 10246" d="M89.587,139.959,90.237,157h4.912Z" transform="translate(108.647 188.172)" fill="#457e5c"/>
          <path id="Path_10247" data-name="Path 10247" d="M152.855,119.092c2.779,0,3-3.715,3-4.85,0-4.021-2.62-5.935-7.271-8.711v8.014c0,5.136,1.855,5.548,4.276,5.548" transform="translate(214.947 126.134)" fill="#457e5c"/>
          <path id="Path_10248" data-name="Path 10248" d="M155.232,102.291l-.115-2.765c-.249-6.523-.958-7.613-3.107-7.761-.221-.017-.387-.025-.541-.025-1.087,0-2.174.191-2.449,2.449-.317,2.875.65,4.393,4.5,7.036q.862.546,1.709,1.067" transform="translate(215.641 101.282)" fill="#457e5c"/>
          <path id="Path_10249" data-name="Path 10249" d="M148.775,103.467h-5.548v3.8h11.636c-2.045-1.186-4.088-2.458-6.089-3.8" transform="translate(205.304 122.416)" fill="#457e5c"/>
          <path id="Path_10250" data-name="Path 10250" d="M155.2,85.855c9.221,0,16.887,2.953,22.172,8.543,4.452,4.713,6.977,11.02,7.487,18.417h6.475l.07-1.21c.5-8.989-1.863-16.529-6.851-21.808-4.783-5.063-11.807-7.739-20.309-7.739-6.98,0-12.721,1.6-17.1,4.59a40.552,40.552,0,0,1,8.056-.793" transform="translate(212.366 83.838)" fill="#457e5c"/>
          <path id="Path_10251" data-name="Path 10251" d="M167.144,96.223H152.4c.678.4,1.353.787,2.017,1.177,4.777,2.777,8.9,5.178,12.373,8.395,6.069,5.624,8.773,12.9,8.773,23.59a34.307,34.307,0,0,1-3.544,16.091c6.489-4.55,10.028-12.2,10.028-22.452,0-10.051-2.379-16.543-7.955-21.715a45.4,45.4,0,0,0-6.952-5.086" transform="translate(221.838 109.363)" fill="#457e5c"/>
          <path id="Path_10252" data-name="Path 10252" d="M181.2,75.746c-1.376,0-3.46,0-3.46,7.871v35.73c0,8.568,2.6,8.568,3.581,8.568,1.129,0,3.228,0,3.228-8.568v-1.914a28,28,0,0,1-.325-4.45V78.843c-.555-3.082-1.762-3.1-3.023-3.1" transform="translate(267.49 72.464)" fill="#457e5c"/>
          <path id="Path_10253" data-name="Path 10253" d="M194.044,66.053c-7.47,0-13.629,1.642-18.347,4.713a46,46,0,0,1,9.3-.916c19.916,0,31.34,10.877,31.34,29.841v35.386c0,8.465-1.3,15.206-3.965,20.382,7.181-4.7,10.446-13.354,10.446-26.745V93.327c0-17.591-10.219-27.274-28.776-27.274" transform="translate(263.812 54.998)" fill="#457e5c"/>
          <path id="Path_10254" data-name="Path 10254" d="M217.415,55.337l8.4,25.873L224.6,51.538H201.379v3.8Z" transform="translate(310.091 28.841)" fill="#457e5c"/>
          <path id="Path_10255" data-name="Path 10255" d="M206.6,77.427l.656,17.042h4.906Z" transform="translate(319.492 75.492)" fill="#457e5c"/>
          <path id="Path_10256" data-name="Path 10256" d="M214.061,55.337h15.36v85.785H235.9V51.538h-22Z" transform="translate(332.665 28.841)" fill="#457e5c"/>
          <path id="Path_10257" data-name="Path 10257" d="M240.469,45.225c-2.838,0-3.956,0-3.956,5.2V63.747h6.481v-14.4c0-3.088-.995-4.116-2.524-4.116" transform="translate(373.401 17.467)" fill="#457e5c"/>
          <path id="Path_10258" data-name="Path 10258" d="M243.72,39.329c26.1,0,31.575,15.786,31.575,29.029v1.418h6.481V62c0-17.56-9.759-26.465-29.011-26.465-7.787,0-13.931,1.577-18.524,4.71a47.244,47.244,0,0,1,9.479-.914" transform="translate(369.306 0)" fill="#457e5c"/>
          <path id="Path_10259" data-name="Path 10259" d="M236.513,66.534c0,3.377,1.087,3.923,3.69,3.923,2.258,0,3.306-.014,3.34-6.949,0-.927-.02-2-.02-3.334h-7.01Z" transform="translate(373.4 44.403)" fill="#457e5c"/>
          <path id="Path_10260" data-name="Path 10260" d="M240.56,50.481v3.8h23.811v46.106h6.481v-49.9Z" transform="translate(380.693 26.937)" fill="#457e5c"/>
          <path id="Path_10261" data-name="Path 10261" d="M29.294,230.924V143.912H49.711v69.609H74.657v17.4Z" transform="translate(0 195.296)" fill="#fff"/>
          <path id="Path_10262" data-name="Path 10262" d="M152.289,190.019l-.93-7.77h-.233c-4.172,7.425-8.815,9.743-17.633,9.743-17.285,0-22.856-11.948-22.856-27.728V128.53c0-18.91,8.7-27.731,27.384-27.731,17.4,0,27.722,7.425,27.722,25.178v4.707H145.326v-2.97c0-7.422-.927-11.138-6.38-11.25-6.377-.115-7.888,3.363-7.888,9.045v43.049c0,4.873,2.205,7.773,7.543,7.773,6.957,0,7.19-5.57,7.19-11.135v-6.848h-7.308V142.683h27.728v47.336Z" transform="translate(146.579 117.608)" fill="#fff"/>
          <path id="Path_10263" data-name="Path 10263" d="M79.7,131.315c-17.865,0-27.846,9.28-27.846,25.988v35.386c0,19.608,7.2,29.819,27.846,29.819s27.5-10.21,27.5-29.819V157.3c0-16.708-9.63-25.988-27.5-25.988m7.081,63.114c0,4.993-.465,12.416-7.081,12.416s-7.425-7.422-7.425-12.416V158.7c0-5.338.813-11.723,7.308-11.723,6.733,0,7.2,6.385,7.2,11.723Z" transform="translate(40.655 172.596)" fill="#fff"/>
          <path id="Path_10264" data-name="Path 10264" d="M99.138,155.089l-.233.233,1.858,48.5H81.388V116.8h21l15.778,48.619h.347L116.541,116.8h19.378v87.02H115.033Z" transform="translate(93.871 146.438)" fill="#fff"/>
          <path id="Path_10265" data-name="Path 10265" d="M159.154,144.774v6.148c0,4.99,1.631,9.4,8.123,9.4,4.758,0,6.845-4.3,6.845-8.7,0-7.31-6.033-10.093-11.37-13.34-6.265-3.6-11.6-6.963-15.43-11.373-3.715-4.407-5.918-9.745-5.918-17.406,0-16.358,9.86-24.713,25.641-24.713,17.75,0,26.8,11.835,25.876,28.2h-18.8c-.23-5.105,0-12.065-6.727-12.531-4.175-.347-6.61,1.97-7.078,5.8-.583,5.217,2.085,7.888,6.147,10.67,7.658,4.875,14.273,7.89,19.025,12.3s7.546,10.21,7.546,20.768c0,16.705-9.283,25.991-26.221,25.991-18.681,0-26.571-10.44-26.571-21.928v-9.283h18.91Z" transform="translate(199.927 88.755)" fill="#fff"/>
          <path id="Path_10266" data-name="Path 10266" d="M196.713,68.782c-17.868,0-27.849,9.28-27.849,25.991v35.386c0,19.608,7.193,29.816,27.849,29.816s27.493-10.208,27.493-29.816V94.773c0-16.711-9.63-25.991-27.493-25.991m7.075,63.114c0,4.99-.468,12.418-7.075,12.418s-7.431-7.428-7.431-12.418V96.166c0-5.338.813-11.721,7.308-11.721,6.73,0,7.2,6.383,7.2,11.721Z" transform="translate(251.501 59.915)" fill="#fff"/>
          <path id="Path_10267" data-name="Path 10267" d="M216.151,92.553l-.235.233,1.86,48.5H198.4V54.267h21l15.778,48.614h.35l-1.973-48.614h19.373v87.018H232.044Z" transform="translate(304.714 33.76)" fill="#fff"/>
          <path id="Path_10268" data-name="Path 10268" d="M269.291,127.481l-.93-7.775h-.233c-4.172,7.428-8.815,9.748-17.633,9.748-17.288,0-22.858-11.95-22.858-27.731V65.993c0-18.913,8.7-27.731,27.386-27.731,17.4,0,27.723,7.422,27.723,25.178v4.7H262.328v-2.97c0-7.419-.925-11.138-6.383-11.25-6.377-.115-7.885,3.363-7.885,9.048v43.047c0,4.873,2.205,7.772,7.543,7.772,6.957,0,7.19-5.567,7.19-11.135V95.808h-7.308V80.145h27.725v47.336h-13.92Z" transform="translate(357.407 4.918)" fill="#fff"/>
        </g>
      </svg>
    )
}

export default SlideOutLandingIcon
